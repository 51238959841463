import * as React from "react";
import { Context } from "bernie-context";
import { convertStringToDate, getNextDate } from "src/components/utility/DateUtils";
import { dialogProviderConfig } from "./helpers/dialog-utils";
import { DateFieldConfig } from "src/stores/wizard/config";
import { DateState } from "src/stores/wizard/state/global";
import { FareCalendar, FieldName, TripType } from "@shared-ui/flights-fare-calendar";
import { LOBState } from "src/stores/wizard/state";
import { MobXProviderContext, observer } from "mobx-react";
import { EGDSLayoutConditionalGridSpan, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { DayName } from "@egds/react-core/date-picker";
import { DialogProvider } from "@shared-ui/dialog-context";
import { useLocalization } from "@shared-ui/localization-context";

export interface DateField {
  fieldName: FieldName;
  value: string;
  price?: number | string;
}

export interface DatesProps {
  singleDate: boolean;
  singleDateSelect?: boolean;
  colSpan?: EGDSLayoutConditionalGridSpan;
  lobState: LOBState;
  multiLegIndex?: number;
  stackedDates?: boolean;
  setInputsRefs?: (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => void;
  startId?: string;
  endId?: string;
  autoOpen?: boolean;
}

export const FlightsFareCalendar: React.FunctionComponent<DatesProps> = observer(
  ({ singleDate, colSpan, lobState, multiLegIndex, stackedDates, startId, endId, setInputsRefs, autoOpen }) => {
    const { formatText, formatDateString, getWeekData } = useLocalization();
    const context: Context = React.useContext(MobXProviderContext).context;
    const [dateConfig, updateDates, dateState] = ((): [
      DateFieldConfig,
      (start: Date, end: Date, multiLegIndex?: number, additionalSingleDate?: boolean) => void,
      DateState
    ] => {
      return [lobState.config.date, lobState.updateDateSelection, lobState.date];
    })();

    const [startDate, setStartDate] = React.useState(formatDateString(dateState.start, { raw: dateConfig.format }));
    const [endDate, setEndDate] = React.useState(formatDateString(dateState.end, { raw: dateConfig.format }));

    // @ts-ignore
    const firstDayOfWeek = parseInt(DayName[getWeekData().firstDay.toUpperCase()], 10);

    const isDesktop = !context?.deviceInformation?.mobile && !context?.deviceInformation?.tablet;

    const isAutoOpenFlightFareCalendar = autoOpen && isDesktop;

    const handleChange = (dateFields: DateField[]): void => {
      let { start, end } = dateState;
      dateFields?.forEach((dateField: DateField) => {
        const { value: date, fieldName } = dateField;
        if (fieldName === FieldName.START_DATE) {
          setStartDate(date);
          start = convertStringToDate(date);
        } else {
          if (date) {
            end = convertStringToDate(date);
          }
          setEndDate(date);
        }
      });
      if (start > end) {
        end = getNextDate(start);
      }
      updateDates(start, end, multiLegIndex);
    };

    const getStartId = (value: string) => {
      return startId || value;
    };

    const getEndId = (value: string) => {
      return endId || value;
    };

    const dateFieldProps: { [key: string]: any } = {
      endId: getEndId("d2"),
      endLabel: formatText(dateConfig.end.labelToken),
      endPlaceholder: formatText(dateConfig.end.labelToken),
      stacked: stackedDates,
      startId: getStartId("d1"),
      startLabel: formatText(dateConfig.start.labelToken),
      startPlaceholder: formatText(dateConfig.start.labelToken),
    };

    const tripType = singleDate ? TripType.ONE_WAY : TripType.ROUND_TRIP;

    const fareCalendarProps = {
      onMountCB: setInputsRefs,
      startDate,
      endDate,
      singleMode: singleDate,
      dateFieldProps,
      onChange: handleChange,
      origin: lobState.location.origin.value,
      destination: lobState.location.destination.value,
      firstDayOfWeek: firstDayOfWeek || DayName.SUN,
      tripType,
      onSubmitAnalytics: true,
      autoOpen: isAutoOpenFlightFareCalendar,
    };
    const rawFormatForSubmission = dateConfig.ISOSubmissionSupport ? dateConfig.format : context.localDateFormat;

    //The first div is the one that adds the UitkLayoutGridItem classes.
    //The second div with the class Dates was added so that custom classes work as expected without using !important in them.
    return (
      <EGDSLayoutGridItem colSpan={colSpan}>
        <div>
          <div className="Dates">
            {/*
                TODO: uitk-new-date-picker not supports setting custom date value format.
                Add start/end hidden inputs with custom value format.
              */}
            <input
              type="hidden"
              name={dateConfig.queryParam.start}
              value={formatDateString(dateState.start, { raw: rawFormatForSubmission })}
            />
            {!singleDate && (
              <input
                type="hidden"
                name={dateConfig.queryParam.end}
                value={formatDateString(dateState.end, { raw: rawFormatForSubmission })}
              />
            )}
            <DialogProvider value={dialogProviderConfig}>
              <FareCalendar {...fareCalendarProps} />
            </DialogProvider>
          </div>
        </div>
      </EGDSLayoutGridItem>
    );
  }
);
