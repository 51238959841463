import * as React from "react";
import { observer } from "mobx-react";

import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGridItem, EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { FlightSubLOBProps } from "components/flexComponents/WizardFlightPWA/typings";
import { Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { Travelers } from "components/shared/Travelers/Travelers";
import { TravelersTriggerTypes } from "components/shared/TravelersField/typings";
import { PreferredClassInput } from "components/shared/PreferredClassInput/PreferredClassInput";
import { PreferredAirlineInput } from "components/shared/PreferredAirlineInput/PreferredAirlineInput";

export const FlightPreferences = observer((props: FlightSubLOBProps) => {
  const {
    flightWizardState,
    flightWizardState: { config: flightConfig },
  } = props.wizardState;

  const { config, isAddAHotelChecked, isAddACarChecked } = flightWizardState;
  const travelerConfig =
    config.enableAddHotel && isAddAHotelChecked
      ? config.travelersWithHotel
      : config.enableAddCar && isAddACarChecked
      ? config.travelersWithCar
      : config.travelers;

  const { noRehydratationValues } = flightConfig.travelers;

  const travelersColSpan: EGDSLayoutConditionalGridSpan = { small: 2, large: 12 };
  const preferredClassColSpan: EGDSLayoutConditionalGridSpan = { small: 1, large: 4 };

  return (
    <Viewport>
      <ViewSmall>
        <EGDSLayoutGridItem colSpan="all">
          <EGDSLayoutFlex space="two">
            <EGDSLayoutFlexItem>
              <Travelers
                lobState={flightWizardState}
                config={travelerConfig}
                colSpan={travelersColSpan}
                noRehydratationValues={noRehydratationValues}
                triggerType={TravelersTriggerTypes.LINK_BUTTON}
              />
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <PreferredClassInput
                colSpan={preferredClassColSpan}
                onSelectPreferredClassCode={props.onSelectPreferredClassCode}
                wizardState={props.wizardState}
              />
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <PreferredAirlineInput
                onSelectPreferredAirline={props.onSelectPreferredAirline}
                wizardState={props.wizardState}
              />
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSLayoutGridItem>
      </ViewSmall>
    </Viewport>
  );
});
