import * as React from "react";
import { observer } from "mobx-react";

import { EGDSTertiaryButton } from "@egds/react-core/button";
import { EGDSIcon } from "@egds/react-core/icons";
import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutConditionalGridTrack,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutConditionalGridStartEnd,
} from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";
import { useLocalization } from "@shared-ui/localization-context";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

import { locKeys, MultiCityFlightLeg } from "components/flexComponents/WizardFlightPWA/components";
import { FlightSubLOBProps } from "components/flexComponents/WizardFlightPWA/typings";

import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { LocationType } from "components/shared/Location/components/LocationType";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";

export const MulticityFlightUXTweaks = observer((props: FlightSubLOBProps) => {
  const { formatText } = useLocalization();
  const [linkDisabled, setLinkDisabled] = React.useState(true);
  /**
   * This hook is reached for rehydratation, here the "add a flight" link gets abled, if JS is disabled then this hook is never executed
   */
  React.useEffect(() => setLinkDisabled(false), []);

  const { getLocError, lobState, setInputsRefs, wizardState } = props;
  const { addALeg, errorInputRef, isMulticityFormValid, listOfAdditionalLegs, updateInvalidFormsState } = lobState;

  if (isMulticityFormValid) {
    updateInvalidFormsState();
  }

  const { maxNumberOfAdditionalLegs } = lobState.config.multiCityFlight;
  const { referrerId } = wizardState.flightWizardState.config;
  const {
    defaultMultipleDestination,
    defaultDestination,
    defaultMultipleOrigin,
    defaultOrigin,
    defaultStopover,
    defaultMultipleStopover,
  } = wizardState.globalWizardState.config;

  const { DESTINATION, ORIGIN } = LocationType;
  if ((Array.isArray(defaultMultipleDestination) || defaultDestination) && lobState.location[DESTINATION]) {
    lobState.location[DESTINATION].value = "";
  }
  if ((Array.isArray(defaultMultipleOrigin) || defaultOrigin) && lobState.location[ORIGIN]) {
    lobState.location[ORIGIN].value = "";
  }
  if (defaultStopover) {
    lobState.location.destination.value = defaultStopover.split(":")[0];
  }
  if (Array.isArray(defaultMultipleStopover)) {
    lobState.location.destination.value = defaultMultipleStopover[0].split(":")[0];
  }

  const gridColumns: EGDSLayoutConditionalGridTrack = { small: 1, medium: 8, large: 12 };
  const addFlightColSpan: EGDSLayoutConditionalGridSpan = { small: 1, medium: 5 };

  const submitColStart: EGDSLayoutConditionalGridStartEnd = { medium: 7, large: 11 };
  const submitColEnd: EGDSLayoutConditionalGridStartEnd = { medium: 9, large: 13 };

  return (
    <EGDSSpacing margin={{ blockstart: "one", blockend: "six" }}>
      <EGDSLayoutGrid columns={gridColumns} space={{ column: "three", row: "six" }}>
        {!isMulticityFormValid && (
          <LobErrorSummary
            locHeadingArgs={lobState.numberOfErrors}
            locHeadingToken={getLocError(false)!}
            locLinkToken={getLocError(true)}
            linkClickFocusId="multi-error"
            inputErrorRef={errorInputRef}
            colSpan={gridColumns as EGDSLayoutConditionalGridSpan}
            setInputsRefs={setInputsRefs}
          />
        )}
        <MultiCityFlightLeg
          lobState={lobState}
          setInputsRefs={setInputsRefs}
          globalConfig={wizardState.globalWizardState.config}
          mainGridColumns={undefined}
          isUsingUXTweaks
        />

        <Viewport>
          <ViewSmall>
            <>
              {listOfAdditionalLegs.length < maxNumberOfAdditionalLegs && (
                <EGDSLayoutGridItem colSpan={addFlightColSpan}>
                  <EGDSText align="right">
                    <EGDSTertiaryButton onClick={addALeg} disabled={linkDisabled}>
                      <EGDSIcon name="add" />
                      {formatText(locKeys.addFlightLabel)}
                    </EGDSTertiaryButton>
                  </EGDSText>
                </EGDSLayoutGridItem>
              )}
            </>
          </ViewSmall>
          <ViewMedium>
            <>
              {listOfAdditionalLegs.length < maxNumberOfAdditionalLegs && (
                <EGDSLayoutGridItem colSpan={addFlightColSpan} alignSelf="center">
                  <div>
                    <EGDSTertiaryButton onClick={addALeg} disabled={linkDisabled}>
                      <EGDSIcon name="add" />
                      {formatText(locKeys.addFlightLabel)}
                    </EGDSTertiaryButton>
                  </div>
                </EGDSLayoutGridItem>
              )}
              <SubmitButton
                colStart={submitColStart}
                colEnd={submitColEnd}
                globalConfig={wizardState.globalWizardState.config}
                lobState={lobState}
                rfrr={referrerId}
                iconName="arrow_forward"
              />
            </>
          </ViewMedium>
        </Viewport>
      </EGDSLayoutGrid>
    </EGDSSpacing>
  );
});
