import * as React from "react";
import { observer } from "mobx-react";

import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import {
  EGDSLayoutGrid,
  EGDSLayoutGridItem,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutConditionalGridTrack,
  EGDSLayoutConditionalGridStartEnd,
} from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { useMediaQueries, defaultViewSizes } from "@shared-ui/viewport-context";

import { FlightSubLOBProps } from "components/flexComponents/WizardFlightPWA/typings";
import {
  FlightAddCarCheckbox,
  FlightAddHotelCheckbox,
  FlightsFareCalendar,
  FlightNonStopCheckbox,
} from "components/flexComponents/WizardFlightPWA/components";

import { Dates } from "components/shared/Dates/Dates";
import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { Location } from "components/shared/Location/Location";
import { LocationType } from "src/components/shared/Location/components/LocationType";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";

export const RoundtripFlightUXTweaks = observer((props: FlightSubLOBProps) => {
  const viewportSize = useMediaQueries({ viewSizes: defaultViewSizes });

  const [isTablet, setIsTablet] = React.useState(false);
  const [isDesktop, setIsDesktop] = React.useState(false);

  React.useEffect(() => {
    setIsTablet(viewportSize === defaultViewSizes.MEDIUM);
    setIsDesktop(viewportSize > defaultViewSizes.MEDIUM);
  }, [viewportSize]);

  const { autoOpenFareCalendar, getLocError, lobState, setInputsRefs, wizardState } = props;
  const {
    errorInputRef,
    isAddAHotelChecked,
    isRoundtripFormValid,
    numberOfErrors,
    updateInvalidFormsState,
    moreThanOneError,
  } = lobState;

  if (isRoundtripFormValid) {
    updateInvalidFormsState();
  }

  const { enableAddCar, enableAddHotel, enableNonStopFlight, enableFlightFareCalendar } = lobState.config;
  const { referrerId } = wizardState.flightWizardState.config;
  const { defaultMultipleStopover, defaultStopover } = wizardState.globalWizardState.config;
  const { DESTINATION } = LocationType;
  if ((defaultMultipleStopover || defaultStopover) && lobState.location[DESTINATION]) {
    lobState.location[DESTINATION].value = "";
  }

  const mainGridColumns: EGDSLayoutConditionalGridTrack = { small: 4, medium: 6, large: 12 };

  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 4, medium: 6, large: 7 };
  const datesColSpan: EGDSLayoutConditionalGridSpan = { small: 4, medium: 6, large: 3 };
  const submitColSpan: EGDSLayoutConditionalGridSpan = { small: 4, medium: 2, large: 2 };

  const submitColStart: EGDSLayoutConditionalGridStartEnd = { medium: 5 };
  const submitColEnd: EGDSLayoutConditionalGridStartEnd = { medium: 7 };

  return (
    <EGDSSpacing margin={{ blockstart: "one", blockend: "six" }}>
      <EGDSLayoutGrid columns={mainGridColumns} space="three" data-testid="inputGrid_uxtweaks">
        {!isRoundtripFormValid && (
          <LobErrorSummary
            locHeadingToken={getLocError(false)!}
            locHeadingArgs={moreThanOneError() && [numberOfErrors]}
            locLinkToken={getLocError(true)}
            linkClickFocusId="id-roundtrip-error"
            inputErrorRef={errorInputRef}
            colSpan={mainGridColumns as EGDSLayoutConditionalGridSpan}
            setInputsRefs={setInputsRefs}
          />
        )}
        <Location
          setInputsRefs={setInputsRefs}
          originField
          destinationField
          lobState={lobState}
          showSwapLocationsControl
          colSpan={locationColSpan}
          globalConfig={wizardState.globalWizardState.config}
          originIcon="flight_takeoff"
          destinationIcon="flight_land"
        />
        {enableFlightFareCalendar ? (
          <FlightsFareCalendar
            singleDate={false}
            lobState={lobState}
            colSpan={datesColSpan}
            singleDateSelect
            setInputsRefs={setInputsRefs}
            autoOpen={autoOpenFareCalendar}
          />
        ) : (
          <Dates lobState={lobState} colSpan={datesColSpan} singleDateSelect setInputsRefs={setInputsRefs} />
        )}
        {isDesktop && (
          <SubmitButton
            colSpan={submitColSpan}
            globalConfig={wizardState.globalWizardState.config}
            lobState={lobState}
            rfrr={referrerId}
            iconName="arrow_forward"
          />
        )}

        {enableAddHotel || enableAddCar || enableNonStopFlight ? (
          <EGDSLayoutGridItem colSpan="all">
            <EGDSSpacing padding={{ blockstart: "one" }}>
              <EGDSLayoutFlex alignItems="center" justifyContent="space-between">
                <EGDSLayoutFlex space="three">
                  {enableAddHotel && <FlightAddHotelCheckbox flightWizardState={lobState} />}
                  {enableAddCar && <FlightAddCarCheckbox flightWizardState={lobState} />}
                  {enableNonStopFlight && <FlightNonStopCheckbox flightWizardState={lobState} />}
                </EGDSLayoutFlex>

                {isTablet && (
                  <EGDSLayoutFlexItem grow={1} maxWidth="quarter_width">
                    <EGDSSpacing margin={{ inlinestart: "four" }}>
                      <SubmitButton
                        globalConfig={wizardState.globalWizardState.config}
                        lobState={lobState}
                        rfrr={referrerId}
                        iconName="arrow_forward"
                      />
                    </EGDSSpacing>
                  </EGDSLayoutFlexItem>
                )}
              </EGDSLayoutFlex>
            </EGDSSpacing>
          </EGDSLayoutGridItem>
        ) : (
          isTablet && (
            <SubmitButton
              colStart={submitColStart}
              colEnd={submitColEnd}
              globalConfig={wizardState.globalWizardState.config}
              lobState={lobState}
              rfrr={referrerId}
              iconName="arrow_forward"
            />
          )
        )}
        {enableAddHotel && isAddAHotelChecked && (
          <Dates lobState={lobState} isPartialStay colSpan={datesColSpan} setInputsRefs={setInputsRefs} />
        )}
      </EGDSLayoutGrid>
    </EGDSSpacing>
  );
});
