import { FlightSubLOBProps } from "components/flexComponents/WizardFlightPWA/typings";
import * as React from "react";
import { observer } from "mobx-react";
import { Location } from "src/components/shared/Location/Location";
import { Dates } from "src/components/shared/Dates/Dates";
import { FlightsFareCalendar } from "./FlightsFareCalendar";
import {
  EGDSLayoutGrid,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { LobErrorSummary } from "src/components/shared/LobErrorSummary/LobErrorSummary";
import { SubmitButton } from "src/components/shared/SubmitButton/SubmitButton";
import { getShoppingGlobalMicroMessage } from "src/components/shared/StorefrontWizard/SubmitRow/UISShoppingGlobal";

export const StickyRoundtripFlight = observer((props: FlightSubLOBProps) => {
  const mainGridColumns: EGDSLayoutConditionalGridTrack = { small: 4, medium: 6, large: 12 };
  const locationColSpan: EGDSLayoutConditionalGridSpan = { small: 4, medium: 6, large: 6 };
  const datesColSpan: EGDSLayoutConditionalGridSpan = { small: 4, medium: 4, large: 4 };
  const submitColSpan: EGDSLayoutConditionalGridSpan = { small: 4, medium: 2, large: 2 };
  const { enableFlightFareCalendar } = props.lobState.config;
  const {
    errorInputRef,
    isRoundtripFormValid,
    numberOfErrors,
    updateInvalidFormsState,
    moreThanOneError,
  } = props.lobState;
  if (isRoundtripFormValid) {
    updateInvalidFormsState();
  }
  const uisShoppingGlobal = getShoppingGlobalMicroMessage(props.lobState);

  return (
    <EGDSSpacing margin={{ blockstart: "two" }}>
      <EGDSLayoutGrid columns={mainGridColumns} space="three">
        {!isRoundtripFormValid && (
          <LobErrorSummary
            locHeadingToken={props.getLocError(false)!}
            locHeadingArgs={moreThanOneError() && [numberOfErrors]}
            locLinkToken={props.getLocError(true)}
            linkClickFocusId="id-roundtrip-error"
            inputErrorRef={errorInputRef}
            colSpan={mainGridColumns as EGDSLayoutConditionalGridSpan}
            setInputsRefs={props.setInputsRefs}
          />
        )}
        <Location
          setInputsRefs={props.setInputsRefs}
          originField
          destinationField
          lobState={props.lobState}
          showSwapLocationsControl
          colSpan={locationColSpan}
        />
        {enableFlightFareCalendar && (
          <FlightsFareCalendar
            singleDate={false}
            lobState={props.lobState}
            colSpan={datesColSpan}
            singleDateSelect
            setInputsRefs={props.setInputsRefs}
          />
        )}
        {!enableFlightFareCalendar && (
          <Dates
            lobState={props.lobState}
            colSpan={datesColSpan}
            singleDateSelect
            setInputsRefs={props.setInputsRefs}
          />
        )}
        <SubmitButton
          lobState={props.lobState}
          colSpan={submitColSpan}
          rfrr={props.flightConfig.referrerId}
          uisPrimeMicroMessages={uisShoppingGlobal}
        />
      </EGDSLayoutGrid>
    </EGDSSpacing>
  );
});
